
export const treeSelectUtils = {
  methods: {
    normalizer (node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children && node.children.length ? node.children : 0
      }
    },
    teamWithMembersNormalizer (node) {
      if (!node.users) {
        return {
          id: node.email,
          label: node.email
        }
      } else {
        return {
          id: node.id,
          label: node.name,
          children: node.users
        }
      }
    }
  }
}
